import React from 'react'
import PropTypes from 'prop-types'

export const UPDATE_AUTH = 'UPDATE_AUTH'
export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO'
export const REMOVE_AUTH_INFO  = 'REMOVE_AUTH_INFO'

export const AuthenticationContext = React.createContext();

export const initialAuthenticationState = {
    session: null,
    email: null,
    isDomainAdmin: true,
    isMimeOSCustomer: false,
    isImpersonating: false,
    customerInfo: {
        customer_id: null,        // messageControl customer
        global_customer_id: null, // mimecast customer ID
        licensed_users: 0, 
        computed_users: 0,
        server_type: null         // kind of customer, like 'mimeos'
    }
}

export const authReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_AUTH:
            return {
                ...state,
                session: action.payload.session,
                email: action.payload.email,
                isDomainAdmin: action.payload.isDomainAdmin || false,
                isMimeOSCustomer: action.payload.isMimeOSCustomer || false,
                isImpersonating: action.payload.isImpersonating || false
            }
        case UPDATE_CUSTOMER_INFO:
            return {
                ...state,
                customerInfo:{
                    customer_id: action.payload.customer_id,
                    global_customer_id: action.payload.global_customer_id,
                    licensed_users: action.payload.licensed_users,
                    computed_users: action.payload.computed_users,
                    server_type: action.payload.server_type,
                }
            }
        case REMOVE_AUTH_INFO:
            return initialAuthenticationState
        default:
            return state;
    }
}

const AuthenticationContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialAuthenticationState);
    return (
        < AuthenticationContext.Provider value={[state, dispatch]}>
            {children}
        </AuthenticationContext.Provider>
    );
}
AuthenticationContextProvider.propTypes = {
    children: PropTypes.node,
}

export default AuthenticationContextProvider