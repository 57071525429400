import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import yellow from '@material-ui/core/colors/yellow'
import amber from '@material-ui/core/colors/amber'
import purple from '@material-ui/core/colors/purple'
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  drawerWidth: 300,
  cardElevation: 3,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  rainbowColors: {
    green: green[700],
    red: red[700],
    blue: blue[700],
    amber: amber[700],
    yellow: yellow[700],
    purple: purple[700],
  },
  warningColors: {
    info: '#1976D2',
    warning: '#F57C00',
    critical: '#D32F2F',
    blacklist: '#9A0007',
  },
  colors: [
    '#FFFFFF',
    '#CCCCCC',
    '#B2B2B2',
    '#999999',
    '#757575',
    '#666666',
    '#333333',
    '#191919',
  ],
  body: '#F2F2F2',
  backgrounds: ['#FFFFFF', '#F2F2F2', '#E5E5E5', '#CCCCCC', '#333333'],
  palette: {
    type: 'light',
    action: {
      hover: 'rgba(0, 0, 0, 0.05)',
      selected: 'rgba(25,118,210,0.1)',
    },
    primary: {
      light: '#63A4FF',
      main: '#1976d2',
      dark: '#004BA0',
    },
    secondary: {
      light: '#ffffff',
      main: '#B2B2B2',
    },
    info: {
      main: '#333333',
    },
    text: {
      primary: '#333333',
      secondary: '#666666'
    },
  },
  typography: {
    fontFamily: "'Open Sans'",
    body1: {
      fontSize: 16,
      lineHeight: '23px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '21px',
    },
    h1: {
      fontSize: 28,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "34px",
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "29px",
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "27px",
    }
  },
  overrides: {
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiCheckbox: {
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableRipple: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableRipple: true,
    },
  },
})

export const darkTheme = createTheme({
  drawerWidth: 300,
  cardElevation: 3,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  body: '#121920',
  colors: [
    '#000000',
    'rgba(255, 255, 255, 0.2)', // '#333333'
    'rgba(255, 255, 255, 0.3)', // '#4D4D4D'
    '#666666',
    'rgba(255, 255, 255, 0.48)', // '#7A7A7A'
    '#999999',
    '#CCCCCC',
    '#E5E5E5',
  ],
  rainbowColors: {
    green: green[400],
    red: red[400],
    blue: blue[400],
    amber: amber[400],
    yellow: yellow[400],
    purple: purple[400],
  },
  backgrounds: ['#1b2a38', '#24323f', '#333f4b', '#49535e', '#d1d4d7'],
  palette: {
    type: 'dark',
    action: {
      hover: 'rgba(255, 255, 255, 0.05)',
    },
    primary: {
      light: '#80D6FF',
      main: '#42A5F5',
      dark: '#0077C2',
    },
    secondary: {
      light: '#1b2a38',
      main: '#b7b7b7',
    },
    info: {
      // light: '#dcdcdc',
      main: '#dcdcdc',
    },
    text: {
      primary: '#CCCCCC',
    },
  },
  typography: {
    fontFamily: "'Open Sans'",
  },
  overrides: {
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiCheckbox: {
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableRipple: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableRipple: true,
    },
  },
})


export default theme;
