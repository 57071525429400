import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

export const LAST_DAY = 'last_day'
export const LAST_WEEK = 'last_week'
export const LAST_MONTH = 'last_month'

export const TIME_FRAME_OPTIONS = {
    [LAST_DAY]: { toggleDisplay: 'Yesterday', contextualizedDisplayText: 'last day', id:'tf-toggle-last-day' },
    [LAST_WEEK]: { toggleDisplay: '7 Days', contextualizedDisplayText: 'last week', id:'tf-toggle-last-week' },
    [LAST_MONTH]: { toggleDisplay: '30 Days', contextualizedDisplayText: 'last month', id:'tf-toggle-last-month' },
}

export const getPrintableTimeWindow = (timeWindow) => {
    return timeWindow.replace('_', ' ')
}

const useStyles = makeStyles((theme) => ({
    dateSelector: {
        float: 'right',
        background: theme.palette.secondary.light,
        height: 32,
        marginBottom: 18

    },
    dateSelectorButton: {
        color: theme.palette.text.primary,
        '&$selected': {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.light,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.light,
            }
        },

    },
    selected: {},
}))


const TimeFrameToggle = ({ timeWindow, onChange }) => {
    const classes = useStyles()
    return (
        <ToggleButtonGroup
            value={timeWindow || LAST_DAY}
            exclusive
            onChange={(event, newTimeFrame) => {
                if (onChange) {
                    onChange(newTimeFrame)
                }
            }}
            aria-label="time window"
            className={classes.dateSelector}
        >
            <ToggleButton id ={TIME_FRAME_OPTIONS[LAST_DAY].id} style={{ borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }} value={LAST_DAY} aria-label="last day" classes={{ root: classes.dateSelectorButton, selected: classes.selected }}>
                <Typography variant='body2'>{TIME_FRAME_OPTIONS[LAST_DAY].toggleDisplay}</Typography>
            </ToggleButton>
            <ToggleButton id ={TIME_FRAME_OPTIONS[LAST_WEEK].id} value={LAST_WEEK} aria-label="last week" classes={{ root: classes.dateSelectorButton, selected: classes.selected }}>
                <Typography variant='body2'>{TIME_FRAME_OPTIONS[LAST_WEEK].toggleDisplay}</Typography>
            </ToggleButton>
            <ToggleButton id ={TIME_FRAME_OPTIONS[LAST_MONTH].id} style={{ borderTopRightRadius: 6, borderBottomRightRadius: 6 }} value={LAST_MONTH} aria-label="last month" classes={{ root: classes.dateSelectorButton, selected: classes.selected }}>
                <Typography variant='body2'>{TIME_FRAME_OPTIONS[LAST_MONTH].toggleDisplay}</Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
TimeFrameToggle.propTypes = {
    timeWindow: PropTypes.string,
    onChange: PropTypes.func,
}

export default TimeFrameToggle