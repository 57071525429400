import React from 'react'
import PropTypes from 'prop-types'
import { LAST_WEEK } from '@mc/common/components/Toggles/TimeFrameToggle'
import { RULE_LEVELS } from '../utils/codebreakerData'

export const UPDATE_EMAIL_ALERT_DATA = 'UPDATE_EMAIL_ALERT_DATA'
export const UPDATE_TRACKING_DATA = 'UPDATE_TRACKING_DATA'
export const UPDATE_COMPETITIVE_DATA = 'UPDATE_COMPETITIVE_DATA'
export const UPDATE_TIME_WINDOW = 'UPDATE_TIME_WINDOW'
export const UPDATE_PENDO_INITIALIZED = 'UPDATE_PENDO_INITIALIZED'


export const AppContext = React.createContext();
// Set up Initial State
export const initiaAppState = {
    emailAlertData: {
        curr_emails: [1],
        prev_emails: [1],
        curr_alerts: [1],
        prev_alerts: [1],
        sum_curr_alerts: 0,
        sum_prev_alerts: 0,
        sum_curr_emails: 0,
        alert_ratio: 0,
        prev_alert_ratio: 0,
        sum_reports: 0,
        sum_safe: 0,
        alert_ratio_change: 0,
        average_alerts_per_inbox_per_day: 0,
        prev_average_alerts_per_inbox_per_day: 0,
        average_alerts_per_inbox_per_day_change: 0,
        curr_alert_types: Object.keys(RULE_LEVELS).reduce((acc, rule) => {
            return { ...acc, [rule]: 0 };
        }, {}),
        prev_alert_types: Object.keys(RULE_LEVELS).reduce((acc, rule) => {
            return { ...acc, [rule]: 0 };
        }, {}),
        userReports: [],
        //should be a const
        curr_levels: ['info', 'warning', 'critical', 'blacklist'].reduce((acc, sev) => {
            return { ...acc, [sev]: 0 };
        }, {}),
        whitelisted_domains: [],
        curr_domains: {},
    },
    trackingData: {
        total: 0,
        totalperc: 0,
        potential: 0,
        potentailperc: 0,
        known: 0,
        knownperc: 0,
    },
    competitiveTrackingData: {
        total_conversations: 0,
        totalperc: 0,
    },
    timeWindow: LAST_WEEK,
    pendoInitialized: false,
};

export const appReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_TIME_WINDOW:
            return {
                ...state,
                timeWindow: action.payload
            }
        case UPDATE_EMAIL_ALERT_DATA:
            return {
                ...state,
                emailAlertData: action.payload
            }
        case UPDATE_TRACKING_DATA:
            return {
                ...state,
                trackingData: action.payload
            }
        case UPDATE_COMPETITIVE_DATA:
            return {
                ...state,
                competitiveTrackingData: action.payload
            }
        case UPDATE_PENDO_INITIALIZED:
            return {
                ...state,
                pendoInitialized: action.payload,
            }
        default:
            return initiaAppState;
    }
}

const AppContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(appReducer, initiaAppState);
    return (
        < AppContext.Provider value={[state, dispatch]}>
            {children}
        </AppContext.Provider>
    );
}
AppContextProvider.propTypes = {
    children: PropTypes.node,
}

export default AppContextProvider