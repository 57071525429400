// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-activity-log-js": () => import("./../../../src/pages/activity-log.js" /* webpackChunkName: "component---src-pages-activity-log-js" */),
  "component---src-pages-dynamic-banners-js": () => import("./../../../src/pages/dynamic-banners.js" /* webpackChunkName: "component---src-pages-dynamic-banners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-summary-page-js": () => import("./../../../src/pages/summary-page.js" /* webpackChunkName: "component---src-pages-summary-page-js" */),
  "component---src-pages-trackers-js": () => import("./../../../src/pages/trackers.js" /* webpackChunkName: "component---src-pages-trackers-js" */)
}

