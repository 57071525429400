export const processRawCodeBreakerSummaryData = (rawCodebreakerData, licensed_users = 200) => {
    let processedCodeBreakerData = {
        curr_window: rawCodebreakerData['current_window'],
        whitelisted_domains: rawCodebreakerData['whitelisted_domains'],
        prev_window: rawCodebreakerData['previous_window'],
        userReports: rawCodebreakerData['user_reports'],
        dates: rawCodebreakerData['current_window'].map(a => a.date),
        curr_emails: rawCodebreakerData['current_window'].map(diffEmailCount),
        prev_emails: rawCodebreakerData['previous_window'].map(diffEmailCount),
        curr_alerts: rawCodebreakerData['current_window'].map(totalAlerts),
        prev_alerts: rawCodebreakerData['previous_window'].map(totalAlerts),
        curr_window_merged: mergeDays(rawCodebreakerData['current_window']),
        prev_window_merged: mergeDays(rawCodebreakerData['previous_window']),
        sum_reports: rawCodebreakerData['user_reports'].filter((r) => {
            return r.action === 'blacklist'
        }).length,
        sum_safe: rawCodebreakerData['user_reports'].filter((r) => {
            return r.action !== 'blacklist'
        }).length,
    }
    processedCodeBreakerData.sum_curr_alerts = processedCodeBreakerData.curr_alerts.reduce((s, f) => {
        return s + f;
    }, 0)
    processedCodeBreakerData.sum_prev_alerts = processedCodeBreakerData.prev_alerts.reduce((s, f) => {
        return s + f;
    }, 0)
    processedCodeBreakerData.sum_curr_emails = processedCodeBreakerData.curr_emails.reduce((s, f) => {
        return s + f;
    }, 0)
    processedCodeBreakerData.sum_prev_emails = processedCodeBreakerData.prev_emails.reduce((s, f) => {
        return s + f;
    }, 0)

    processedCodeBreakerData.curr_alert_types = groupByAndSum(
        processedCodeBreakerData.curr_window_merged['alert_types'], 'rule', 'count'
    )
    processedCodeBreakerData.prev_alert_types = groupByAndSum(
        processedCodeBreakerData.prev_window_merged['alert_types'], 'rule', 'count'
    )

    processedCodeBreakerData.curr_domains = groupByAndSum(
        processedCodeBreakerData.curr_window_merged['alert_domains'], 'domain', 'count'
    )
    processedCodeBreakerData.prev_domains = groupByAndSum(
        processedCodeBreakerData.prev_window_merged['alert_domains'], 'rule', 'count'
    )

    let total_curr_email = processedCodeBreakerData.sum_curr_alerts + processedCodeBreakerData.sum_curr_emails
    processedCodeBreakerData.alert_ratio
        = total_curr_email ? processedCodeBreakerData.sum_curr_alerts / total_curr_email : 0
    let total_prev_email = processedCodeBreakerData.sum_prev_alerts + processedCodeBreakerData.sum_prev_emails
    processedCodeBreakerData.prev_alert_ratio
        = total_prev_email ? processedCodeBreakerData.sum_prev_alerts / total_prev_email : 0

    processedCodeBreakerData.curr_levels = groupRuleLevels(processedCodeBreakerData.curr_alert_types)

    processedCodeBreakerData['alert_ratio_change']
        = processedCodeBreakerData.prev_alert_ratio
            ? (100 *
                (processedCodeBreakerData.alert_ratio - processedCodeBreakerData.prev_alert_ratio)
                / processedCodeBreakerData.prev_alert_ratio).toFixed(0)
            : 0

    processedCodeBreakerData['average_alerts_per_inbox_per_day']
        = processedCodeBreakerData.curr_emails.length > 0 && licensed_users > 0
            ? Math.ceil(processedCodeBreakerData.sum_curr_alerts / processedCodeBreakerData.curr_emails.length / licensed_users) : 0

    processedCodeBreakerData['prev_average_alerts_per_inbox_per_day']
        = processedCodeBreakerData.prev_emails.length > 0 && licensed_users > 0
            ? Math.ceil(processedCodeBreakerData.sum_prev_alerts / processedCodeBreakerData.prev_emails.length / licensed_users) : 0

    processedCodeBreakerData['average_alerts_per_inbox_per_day_change']
        = processedCodeBreakerData.prev_average_alerts_per_inbox_per_day
            ? (100 * (processedCodeBreakerData.average_alerts_per_inbox_per_day - processedCodeBreakerData.prev_average_alerts_per_inbox_per_day)
                / processedCodeBreakerData.prev_average_alerts_per_inbox_per_day
            ).toFixed(0) : null
    return processedCodeBreakerData

}

export const RULE_NAMES = {
    AddressLengthRule: 'AddressLengthRule',
    BlacklistedAddressRule: 'BlacklistedAddressRule',
    BlacklistedDomainRule: 'BlacklistedDomainRule',
    FirstCompanyInboundCommRule: 'FirstCompanyInboundCommRule',
    FirstCompanyOutboundCommRule: 'FirstCompanyOutboundCommRule',
    FirstGroupInboundCommRule: 'FirstGroupInboundCommRule',
    FirstGroupOutboundCommRule: 'FirstGroupOutboundCommRule',
    MarketingSpamRule: 'MarketingSpamRule',
    PersonalInternationalEmailRule: 'PersonalInternationalEmailRule',
    PersonalEmailRule: 'PersonalEmailRule',
    PunyEmailRule: 'PunyEmailRule',
    PunyNameRule: 'PunyNameRule',
    PunySubjectRule: 'PunySubjectRule',
    RecipientInclusionRule: 'RecipientInclusionRule',
    SalesSpamRule: 'warnSalesSpamRuleing',
    SimilarNameDifferentEmailRule: 'SimilarNameDifferentEmailRule',
    SimilarNameWithinCompanyRule: 'SimilarNameWithinCompanyRule',
    SPFRecordExistsRule: 'SPFRecordExistsRule',
    SPFSenderFailedRule: 'SPFSenderFailedRule',
    YoungDomainRule: 'YoungDomainRule',
}

export const RULE_LEVELS = {
    FirstCompanyInboundCommRule: 'info',
    FirstCompanyOutboundCommRule: 'info',
    FirstGroupInboundCommRule: 'info',
    FirstGroupOutboundCommRule: 'info',
    SimilarNameWithinCompanyRule: 'warning',
    SimilarNameDifferentEmailRule: 'warning',
    PunyNameRule: 'critical',
    PunyEmailRule: 'critical',
    PunySubjectRule: 'critical',
    YoungDomainRule: 'critical',
    PersonalInternationalEmailRule: 'warning',
    PersonalEmailRule: 'info',
    AddressLengthRule: 'warning',
    SalesSpamRule: 'warning',
    MarketingSpamRule: 'info',
    RecipientInclusionRule: 'info',
    BlacklistedDomainRule: 'blacklist',
    BlacklistedAddressRule: 'blacklist',
    SPFRecordExistsRule: 'warning',
    SPFSenderFailedRule: 'warning',
}


const diffEmailCount = (data) => {
    return parseInt(data['total_emails']) - parseInt(data['total_alerts']);
}

const totalAlerts = (data) => {
    return parseInt(data['total_alerts']);
}

const groupByAndSum = (data, groupField, countField) => {
    return data.reduce(function (acc, item) {
        if (!acc[item[groupField]]) {
            acc[item[groupField]] = 0;
        }
        acc[item[groupField]] += parseInt(item[countField]);
        return acc;
    }, {});
}

const groupRuleLevels = (data) => {
    return Object.keys(data).reduce(function (acc, ruleName) {
        if (!acc[RULE_LEVELS[ruleName]]) {
            acc[RULE_LEVELS[ruleName]] = 0;
        }
        acc[RULE_LEVELS[ruleName]] += parseInt(data[ruleName]);
        return acc;
    }, {
        'info': 0,
        'warning': 0,
        'critical': 0,
        'blacklist': 0
    });
}

const mergeDays = (data) => {
    return data.reduce((acc, day_item) => {
        return {
            'alert_domains': acc['alert_domains'].concat(day_item['alert_domains']),
            'alert_types': acc['alert_types'].concat(day_item['alert_types']),
            'total_alerts': acc['total_alerts'] + parseInt(day_item['total_alerts']),
            'total_emails': acc['total_emails'] + parseInt(day_item['total_emails']),
        }
    }, {
        'alert_domains': [],
        'alert_types': [],
        'total_alerts': 0,
        'total_emails': 0,
    })
}

