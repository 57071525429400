import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import AppContextProvider from './AppContext'
import AuthenticationContextProvider from './AuthenticationContext'

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.body,
        },
    },
}))


const AppWrapper = ({ children }) => {
    useStyles()
    return (
        <AuthenticationContextProvider>
            <AppContextProvider>
                {children}
            </AppContextProvider>
        </AuthenticationContextProvider>

    );
}
AppWrapper.propTypes = {
    children: PropTypes.node,
}

export default AppWrapper